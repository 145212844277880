export default function  Connections () {
    


   


    return (
    <div className="pageTitle">
      <h1>Connections</h1>
     
    </div>
  );
}