export const TeamAbbrMapping = {
  ARI: { name: "Arizona", id: 22 },
  ATL: { name: "Atlanta", id: 1 },
  BAL: { name: "Baltimore", id: 33 },
  BUF: { name: "Buffalo", id: 2 },
  CAR: { name: "Carolina", id: 29 },
  CHI: { name: "Chicago", id: 3 },
  CIN: { name: "Cincinnati", id: 4 },
  CLE: { name: "Cleveland", id: 5 },
  DAL: { name: "Dallas", id: 6 },
  DEN: { name: "Denver", id: 7 },
  DET: { name: "Detroit", id: 8 },
  GB: { name: "Green Bay", id: 9 },
  HOU: { name: "Houston", id: 34 },
  IND: { name: "Indianapolis", id: 11 },
  JAX: { name: "Jacksonville", id: 30 },
  KC: { name: "Kansas City", id: 12 },
  LAC: { name: "LA Chargers", id: 24 },
  LAR: { name: "LA Rams", id: 14 },
  LV: { name: "Las Vegas", id: 13 },
  MIA: { name: "Miami", id: 15 },
  MIN: { name: "Minnesota", id: 16 },
  NE: { name: "New England", id: 17 },
  NO: { name: "New Orleans", id: 18 },
  NYG: { name: "NY Giants", id: 19 },
  NYJ: { name: "NY Jets", id: 20 },
  PHI: { name: "Philadelphia", id: 21 },
  PIT: { name: "Pittsburgh", id: 23 },
  SEA: { name: "Seattle", id: 26 },
  SF: { name: "San Francisco", id: 25 },
  TB: { name: "Tampa Bay", id: 27 },
  TEN: { name: "Tennessee", id: 10 },
  WSH: { name: "Washington", id: 28 },
};


export const TeamNameMapping = {
  "CARDINALS": { code: "ARI", id: 22 },
  "FALCONS": { code: "ATL", id: 1 },
  "RAVENS": { code: "BAL", id: 33 },
  "BILLS": { code: "BUF", id: 2 },
  "PANTHERS": { code: "CAR", id: 29 },
  "BEARS": { code: "CHI", id: 3 },
  "BENGALS": { code: "CIN", id: 4 },
  "BROWNS": { code: "CLE", id: 5 },
  "COWBOYS": { code: "DAL", id: 6 },
  "BRONCOS": { code: "DEN", id: 7 },
  "LIONS": { code: "DET", id: 8 },
  "PACKERS": { code: "GB", id: 9 },
  "TEXANS": { code: "HOU", id: 34 },
  "COLTS": { code: "IND", id: 11 },
  "JAGUARS": { code: "JAX", id: 30 },
  "CHIEFS": { code: "KC", id: 12 },
  "CHARGERS": { code: "LAC", id: 24 },
  "RAMS": { code: "LAR", id: 14 },
  "RAIDERS": { code: "LV", id: 13 },
  "DOLPHINS": { code: "MIA", id: 15 },
  "VIKINGS": { code: "MIN", id: 16 },
  "PATRIOTS": { code: "NE", id: 17 },
  "SAINTS": { code: "NO", id: 18 },
  "GIANTS": { code: "NYG", id: 19 },
  "JETS": { code: "NYJ", id: 20 },
  "EAGLES": { code: "PHI", id: 21 },
  "STEELERS": { code: "PIT", id: 23 },
  "SEAHAWKS": { code: "SEA", id: 26 },
  "49ERS": { code: "SF", id: 25 },
  "BUCCANEERS": { code: "TB", id: 27 },
  "TITANS": { code: "TEN", id: 10 },
  "COMMANDERS": { code: "WSH", id: 28 },
};



